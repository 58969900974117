<template>
  <div
    class="subheader py-2 py-lg-4"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="
        d-flex
        align-items-center
        justify-content-between
        flex-wrap flex-sm-nowrap
      "
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-inline-flex align-items-center flex-wrap mr-1">
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ breadcrumbs.length && breadcrumbs[0].title }}
        </h5>
        <ul
          v-if="false"
          class="
            breadcrumb breadcrumb-transparent breadcrumb-dot
            font-weight-bold
            p-0
            my-2
          "
        >
          <li class="breadcrumb-item d-inline-flex">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li
              class="breadcrumb-item d-inline-flex"
              :key="`${i}-${breadcrumb.id}`"
            >
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span class="text-muted" :key="i" v-if="!breadcrumb.route">
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div class="d-flex align-items-center">
        <div v-if="title == 'Permissoes'">
          <b-button-group>
            <router-link to="/createRule" v-slot="{ navigate }">
              <b-button
                v-show="lista_permissoes_filial.c_Role"
                @click="navigate"
                class="btn btn-light font-weight-bold btn-sm"
                variant="default"
                style="color: black"
              >
                {{ $t("GERENCIAR.CRIAR") }}
              </b-button>
            </router-link>

            <router-link to="/permissoes" v-slot="{ navigate }">
              <b-button
                v-show="lista_permissoes_filial.ra_Role"
                @click="navigate"
                class="btn btn-light font-weight-bold btn-sm ml-1"
                variant="default"
                style="color: black"
              >
                Listar
              </b-button>
            </router-link>
          </b-button-group>
        </div>
      </div>

      <div v-if="title == 'Funcionarios'">
        <b-button-group>
          <router-link to="/gerenFuncionarios" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createFuncionario" v-slot="{ navigate }">
            <b-button
              v-show="lista_permissoes_filial.c_Usuario"
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("CREACION.NOME") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Paciente'">
        <b-button-group>
          <router-link to="/gerenPaciente" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createPaciente" v-slot="{ navigate }">
            <b-button
              v-show="lista_permissoes_filial.c_Usuario"
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("CREACION.NOME") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Empresa'">
        <b-button-group>
          <router-link to="/empresa" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createEmpresa" v-slot="{ navigate }">
            <b-button
              v-show="lista_permissoes_filial.c_Empresa"
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("CREACION.NOME") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Filial'">
        <b-button-group>
          <router-link to="/filial" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createFilial" v-slot="{ navigate }">
            <b-button
              v-show="lista_permissoes_filial.c_Filial"
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("CREACION.NOME") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Año'">
        <b-button-group>
          <router-link to="/ano" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createAno" v-slot="{ navigate }">
            <b-button
              v-show="lista_permissoes_filial.c_Ano"
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("CREACION.NOME") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Salas'">
        <b-button-group>
          <router-link to="/sala" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createSala" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("SALA.NOVA_SALA") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Vinculos Salas'">
        <b-button-group>
          <router-link to="/vinculo_sala" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/create_vinculo_sala" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("VINCULO.NOVO_VINCULO") }}
            </b-button>
          </router-link>
          <router-link to="/agendamentos" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            > 
              Agendamento
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Productos'">
        <b-button-group>
          <router-link to="/produto" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createProduto" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("PRODUTOS.NOVO_PRODUTO") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Laboratorios'">
        <b-button-group>
          <router-link to="/laboratorio" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createLaboratorio" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("LABORATORIO.NOVO_LAB") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Substancias'">
        <b-button-group>
          <router-link to="/substancia" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createSubstancia" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("SUBSTANCIA.NOVA_SUBSTANCIA") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Tipos'">
        <b-button-group>
          <router-link to="/TipoProduto" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createTipoProduto" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("TIPOPRODUTO.NOME") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Local'">
        <b-button-group>
          <router-link to="/local" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createLocal" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("LOCAL.NOVO_LOCAL") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Setor'">
        <b-button-group>
          <router-link to="/setor" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createSetor" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("SETOR.NOVO_SETOR") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>
      
      <div v-if="title == 'Perfil'">
        <b-button-group>
          <router-link to="/perfil" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createPerfil" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              Novo Perfil
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Arquivos'">
        <b-button-group>
          <router-link to="/Arquivos" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createArquivos" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              Novo
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Atendimiento'">
        <b-button-group>
          <router-link to="/atendimento" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createAtendimento" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("GERENCIAR.CRIAR") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Historico del Paciente'">
        <b-button-group>
          <router-link to="/atendimento" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createAtendimento" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              Nuevo atendimiento
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Tipos Classe'">
        <b-button-group>
          <router-link to="/tipoClasse" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createTipoClasse" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("CREACION.NOME") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Salida de Producto'">
        <b-button-group>
          <router-link to="/saidaProduto" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createSaidaproduto" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("CREACION.NOME") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Especialidad'">
        <b-button-group>
          <router-link to="/listaEspecialidade" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createEspecialidade" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              Nueva
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Diagnóstico'">
        <b-button-group>
          <router-link to="/listarDiagnostico" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Listar
            </b-button>
          </router-link>
          <router-link to="/createDiagnostico" v-slot="{ navigate }">
            <b-button
              @click="navigate"
              class="btn btn-light font-weight-bold btn-sm ml-1"
              variant="default"
              style="color: black"
            >
              {{ $t("CREACION.NOME") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Agendamentos'">
        <b-button-group>
          <router-link to="/vinculo_sala" v-slot="{ navigate }">
            <b-button
              @click="navigate" 
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
              Accesar Vinculos Sala
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Relatorios'">
        <b-button-group>
          <router-link to="/createrelatorio" v-slot="{ navigate }">
            <b-button
              @click="navigate" 
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
            {{ $t("PRODUTOS.PRODUTO") }}
            </b-button>
          </router-link>
          <router-link to="/relatorioEstoque" v-slot="{ navigate }">
            <b-button
              @click="navigate" 
              class="btn btn-light font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
            {{ $t("ESTOQUES.ATIVOS") }}
            </b-button>
          </router-link>
        </b-button-group>
      </div>

      <div v-if="title == 'Relatorio Geral'">
        <!-- <b-button-group>
          <router-link to="/relatorioFilial" v-slot="{ navigate }">
            <b-button
              @click="navigate" 
              class="btn btn-outline-success font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
            {{ $t("RELATORIO.FILIAL") }}
            </b-button>
          </router-link>
         
        </b-button-group> -->
      </div>

      <div v-if="title == 'Fila Atendimientos'">
        <b-button-group>
          <router-link to="/relatorioFilial" v-slot="{ navigate }">
            <b-button
              @click="navigate" 
              class="btn btn-outline-secondary font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
           Atendimientos Recientes
            </b-button>
          </router-link>
          <router-link to="/relatorioFilial" v-slot="{ navigate }">
            <b-button
              @click="navigate" 
              class="btn btn-outline-secondary font-weight-bold btn-sm"
              variant="default"
              style="color: black"
            >
            Historico de atendimentos
            </b-button>
          </router-link>
         
        </b-button-group>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    lista_filiais_autorizadas() {
      return this.$store.state.sistema.lista_filiais_autorizadas;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
  },
  // methods: {
  //    guardarDados(value) {
  //     this.$router.push({ name: "createGradeMatriculas" });
  //     this.$store.dispatch("guardarDados", value);
  //   },
  // }
};
</script>
