<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <vue-confirm-dialog></vue-confirm-dialog>
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile xskmxskxs-->

    <!-- <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader> -->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- <Alert></Alert> -->

        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
            id="kt_content"
            class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
              v-if="subheaderDisplay"
              v-bind:breadcrumbs="breadcrumbs"
              v-bind:title="pageTitle"
          />
          
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
                :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <loading
                  :active.sync="isLoading"
                  :can-cancel="canCancel"
                  :is-full-page="fullPage"
                  :height="height"
                  :width="width"
                  :color="color"
                  :loader="loader"
              >
              </loading>

              <transition name="fade-in-up">
                <router-view/>
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>

    <KTScrollTop></KTScrollTop>

    <div>
      <b-modal v-model="modalfilial" no-close-on-backdrop no-close-on-esc hide-header-close hide-footer centered>
        <template #modal-title class="">
          Escolha uma Filial
          <button
              class="btn btn-icon btn-hover-light-primary position-absolute right-0 top-0 mt-3 mr-8"
              @click="logout"
          >
            <i class="fas fa-sign-out-alt"></i>
          </button>
        </template>
        <b-table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            :items="lista_filiais_autorizadas"
            show-empty
            empty-html="Nenhuma filial autorizada para seu perfil</p>
              <p>Contate o administrador.</p>"
            :fields="['filial','acao']"
        >
          <template #head(acao)=""><span></span></template>
          <template #head(filial)="">
            <div>Sede</div>
          </template>
          <template #cell(acao)="{item}">
            <div class="text-right w-100">
              <a href="#" class="btn btn-icon btn-light btn-sm" @click="selectFilial(item)">
                <span class="svg-icon svg-icon-md svg-icon-danger">
                      <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg">
                      </inline-svg>
                  </span>
              </a>
            </div>
          </template>
        </b-table>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";

import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/store/htmlclass.module.js";

export default {
  name: "Layout",

  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTScrollTop,
    Loading,
  },

  data: function () {
    return {
      fullPage: true,
      container: this.$refs.loadingContainer,
      canCancel: true, // default false
      onCancel: this.yourCallbackMethod,
      color: "#7a0000",
      useSlot: true,
      loader: "spinner",
      height: 60,
      width: 60,
      backgroundColor: "#110f38",
      opacity: 0.9,
      modalfilial: false,
    };
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({name: "login"});
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  created() {
    this.setFilialGuardada()
  },

  methods: {
    logout() {
      this.$router.push({name: 'login'})
      this.$store.dispatch('auth/logout')
    },
    hideModalFilial() {
      this.modalfilial = false;
    },
    async selectFilial(filialId) {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("sistema/setFilial", filialId.id);
      await this.$store.dispatch("sistema/setEmpresa", filialId.empresa_id);
      await this.$store.dispatch("sistema/listar_permissoes_sede", filialId.id);
      await this.$store.dispatch("relatorios/get_num_atentimentos");
      this.hideModalFilial()
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      location.reload()
     
     
    },
    async setFilialGuardada() {
      await this.$store.dispatch("sistema/setFilialGuardada");

      if (this.$store.state.sistema.lista_permissoes_filial == "") {
        await this.$store.dispatch("sistema/listar_filiais_autorizadas");

        this.modalfilial = true;
      }

    },
  },
  computed: {
    lista_filiais_autorizadas() {
      return Object.values(this.$store.state.sistema.lista_filiais_autorizadas)
    },
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    isLoading() {
      return this.$store.state.configEmpresa.isLoading;
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
