<template>
  <div class="topbar-item">
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
        Olá,
      </span>
      <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
        {{ currentUser.name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUser.name.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">
          Perfil do Usuário
          <small class="text-muted font-size-sm ml-2">0 messages</small>
        </h3>
        <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->
      <!--begin::Content-->
      <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative;">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="currentUser.profile_photo_url" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <router-link to="/custom-pages/profile" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
              {{ currentUser.name }}
            </router-link>
            <!--            <div class="text-muted mt-1">Application Developer</div>-->
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg src="media/svg/icons/Communication/Mail-notification.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sair
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link to="/" @click="closeOffcanvas" href="#" class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/General/Notification2.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <router-link to="/createFuncionario">
                  <div class="font-weight-bold">
                    Meus dados
                    <span class="label label-light label-inline font-weight-bold">
                      em breve
                    </span>
                  </div>
                </router-link>
                <div class="text-muted">Account settings and more</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <div @click="closeOffcanvas" class="navi-item">
            <a type="button" data-bs-toggle="modal" data-bs-target="#trocar_senha" @click="open">
              <div class="navi-link">
                <div class="symbol symbol-40 bg-light mr-3">
                  <div class="symbol-label">
                    <span class="svg-icon svg-icon-md txt-primary svg-icon-warning">
                      <!--begin::Svg Icon-->
                      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2023-06-08-055059/core/html/src/media/icons/duotune/general/gen047.svg-->
                      <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24"
                          fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                          <path
                            d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">
                    {{ $t("AUTH.GENERAL.TROCAR") }}

                  </div>
                  <div class="text-muted">Tarefas e mensagens</div>
                </div>
              </div>
            </a>
          </div>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link to="/" @click="closeOffcanvas" href="#" class="navi-item">
            <!-- <TrocarSenha></TrocarSenha> -->
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Files/Selected-file.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  Registro de Atividades
                  <span class="label label-light label-inline font-weight-bold">
                    em breve
                  </span>
                </div>
                <div class="text-muted">Logs e notificações</div>
              </div>
            </div>
          </router-link>



          <!--end:Item-->
          <!--begin::Item-->
          <router-link to="/" @click="closeOffcanvas" href="#" class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Mail-opened.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  Recados
                  <span class="label label-light label-inline font-weight-bold">
                    em breve
                  </span>
                </div>
                <div class="text-muted">Últimas tarefas</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
        </div>
        <!--end::Nav-->
        <div class="separator separator-dashed my-7"></div>
        <!--begin::Notifications-->

        <!--end::Notifications-->
      </perfect-scrollbar>

    </div>
    <b-modal ref="trocar_senha" centered id="trocar_senha" :title="$t('AUTH.GENERAL.TROCAR')">

      <div class="form">
        <div class="form-group row">
          <div class="col-md-12">
            <label class="col-md-12">{{ $t('AUTH.REGISTER.DSENHAATUAL') }}</label>
            <input required type="password" class="form-control" v-model="senha_atual" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <label class="col-md-12"> {{ $t('AUTH.REGISTER.DSENHA') }} </label>
            <input required type="password" class="form-control" v-model="nova_senha" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <label class="col-md-12">{{ $t('AUTH.REGISTER.CSENHA') }}</label>
            <!-- <input v-if="confirmar_senha == ''" required type="text" class="form-control" v-model="confirmar_senha" /> -->
            <input type="password" v-model="confirmar_senha"
              :class="{ 'is-invalid form-control': senhaIgual == 1, 'is-valid form-control': senhaIgual == 2, 'form-control': senhaIgual == 0 }">
          </div>

        </div>
        <!-- <div class="form-group row">
          
        </div> -->

      </div>

      <template #modal-footer>
        <div class="w-100 text-center">
          <div class="text-center">
            <button @click.prevent="trocar_senha" class="btn btn-primary" :disabled="verif">
              {{ $t('GERENCIAR.NOME') }}
              <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
            </button>

            <button @click.prevent="cancelar" class="btn btn-secondary" :disabled="verif">
              Cancelar
            </button>
          </div>
          <!-- <b-button
                    variant="danger"
                    
                    class=" btn-light float-right mt-3"
                    @click="$bvModal.hide('estoque')"
                    >Fechar</b-button
                  > -->
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
// import TrocarSenha from "../offcanvas/TrocarSenha.vue";
export default {
  mixins: [fireAlert],
  name: "KTQuickUser",
  components: {
    // TrocarSenha
  },
  data() {

    return {
      nova_senha: "",
      senha_atual: "",
      confirmar_senha: "",
      verif: false,
      senhaIgual: 0
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  watch: {

    confirmar_senha() {
      if (this.confirmar_senha != this.nova_senha) {
        this.senhaIgual = 1
      } else {
        this.senhaIgual = 2
      }
    }
  },
  computed: {
    mensagem_alert() {
      return this.$store.state.configUsuarios.mensagem_alert;
    },
  },

  methods: {
    verificarSenhaSegura(senha) {
      // Verifica o comprimento mínimo da senha
      if (senha.length < 8) {
        return false;
      }

      // Verifica se a senha contém letras maiúsculas, minúsculas e números
      if (!/[A-Z]/.test(senha) || !/[a-z]/.test(senha) || !/[0-9]/.test(senha)) {
        return false;
      }

      // Verifica se a senha contém caracteres especiais
      if (!/[!@#$%^&*()\-_=+{}[\]|\\:;'<>,.?/~`]/.test(senha)) {
        return false;
      }

      // A senha passou por todas as verificações e é considerada segura
      return "ok";
    },

    onLogout() {
      this.$store.dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    async trocar_senha() {
      const user = await JSON.parse(window.localStorage.getItem('ucpUsrInf'))

      var dados = {
        senha_atual: this.senha_atual,
        password: this.nova_senha,
        id: user.id
      }

      this.verif = true;
      await this.$store.dispatch("configUsuarios/troca_senha", dados);
      this.verif = false;

console.log(this.mensagem_alert)
      this.fireAlert({
        title: this.mensagem_alert.tipo,
        tipo: this.mensagem_alert.message,
        routeName: "login",
        timer: 5000,
      })
      this.$refs["trocar_senha"].hide();
    },
    open() {
      this.$refs["trocar_senha"].show();
    },
    cancelar() {
      this.$refs["trocar_senha"].hide();
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", 'currentUser']),
  }
};
</script>
