export default [
  {
    title: "Página Inicial",
    route: "/dashboard",
    iconClass: "fas fa-chalkboard-teacher",
  },
  {
    title: "Gerenciamento",
    route: null,
    iconClass: "fas fa-cogs",
    subItems: [
      {
        title: "Funcionarios",
        route: "/gerenFuncionarios",
        permissao: "ra_Usuario",
      },
      {
        title: "Permissões",
        route: "/permissoes",
        permissao: "c_Permission",
        role:"root"
      },

      {
        title: "Empresa",
        route: "/empresa",
        permissao: "ra_Empresa",
        role:"root"
      },
      {
        title: "Filial",
        route: "/filial",
        permissao: "ra_Filial",
        role:"root"
      },
      {
        title: "Agendamentos",
        route: "/agendamentos",
        permissao: "ra_Filial",
      },

      {
        title: "Perfil",
        route: "/perfil",
        permissao: "ra_Perfil",
        role:"root"
      },
      {
        title: "Especialidades",
        route: "/listaEspecialidade",
        permissao: "ra_Perfil",
      },

      {
        title: "Diagnostico",
        route: "/listarDiagnostico",
        permissao: "ra_Perfil",
      },
    ],
  },

  {
    title: "Clinica",
    route: null,
    // <font-awesome-icon icon="x" />
    iconClass: "fas fa-clinic-medical",
    subItems: [
      {
        title: "Atendimentos",
        route: "/atendimento",
        permissao: "ra_Atendimento",
        role:"Recepción"
      },
      {
        title: "Triajes",
        route: "/triaje",
        permissao: "ra_Atendimento",
        role:"Triaje"
      },
      {
        title: "Mis consultas",
        route: "/consulta",
        permissao: "ra_Atendimento",
        role:"Medico(a)"
      },
      {
        title: "Medicamentos",
        route: "/estoquesAtivos",
        permissao: "ra_Atendimento",
      },
      {
        title: "Relatorio Saidas",
        route: "/relatorioSaidas",
        permissao: "ra_Atendimento", //CRIAR PERMISSÕES SAIDAS E RELATORIO
      },
    
      {
        title: "Paciente",
        route: "/gerenPaciente",
        permissao: "ra_Usuario",
      },
    
    ],
  },

  {
    title: "Farmacia",
    route: null,
    iconClass: "fas fa-capsules",
    subItems: [ 
      {
        title: "Prescripciones",
        route: "/listReceitas",
        permissao: "ra_Produto",
      },
      {
        title: "Produto",
        route: "/produto",
        permissao: "ra_Produto",
      },
      {
        title: "Relatorio",
        route: "/createrelatorio",
        permissao: "ra_Produto",
      },
      {
        title: "Saida Produto",
        route: "/saidaProduto",
        permissao: "ra_Produto",
      },
      {
        title: "Transferencias",
        route: "/transferencias",
        permissao: "ra_Produto",
      },
      {
        title: "Laboratorio",
        route: "/laboratorio",
        permissao: "ra_Laboratorio",
      },
      {
        title: "Tipo Produto",
        route: "/tipoProduto",
        permissao: "ra_TipoProduto",
      },
      {
        title: "Substância",
        route: "/substancia",
        permissao: "ra_Substancias",
      },
      {
        title: "Tipo Classe",
        route: "/tipoClasse",
        permissao: "ra_TipoProduto",
      },
    ],
  },
  
  {
    title: "Salas",
    route: null,
    iconClass: "fas fa-home",
    subItems: [
      {
        title: "Especialidades por Salas",
        route: "/vinculo_sala",
        permissao: "ra_Sala",
      },
      {
        title: "Salas",
        route: "/sala",
        permissao: "ra_Sala",
      },
      {
        title: "Local",
        route: "/local",
        permissao: "ra_Local",
      },
      {
        title: "Setor",
        route: "/setor",
        permissao: "ra_Setor",
      },
    ],
  },

  {
    title: "Relatórios",
    route: null,
    iconClass: "fas fa-chart-pie",
    subItems: [
      {
        title: "Atendimentos",
        route: "/relatorioAtendimentos",
        permissao: "ra_Atendimento",
      },
      {
        title: "Relatorio Geral",
        route: "/relatorioGeral",
        permissao: "ra_Atendimento", //CRIAR PERMISSÕES SAIDAS E RELATORIO
      },
      // {
      //   title: "Atendimentos",
      //   route: "/relatorioAtendimentos",
      //   permissao: "ra_Sala",
      // },
      // {
      //   title: "Relatorio Geral",
      //   route: "/relatorioGeral",
      //   permissao: "ra_Atendimento", //CRIAR PERMISSÕES SAIDAS E RELATORIO
      // },
   
    ],
  },
];
