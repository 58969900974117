var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subheader py-2 py-lg-4",class:_vm.subheaderClasses,attrs:{"id":"kt_subheader"}},[_c('div',{staticClass:"\n      d-flex\n      align-items-center\n      justify-content-between\n      flex-wrap flex-sm-nowrap\n    ",class:{ 'container-fluid': _vm.widthFluid, container: !_vm.widthFluid }},[_c('div',{staticClass:"d-inline-flex align-items-center flex-wrap mr-1"},[_c('h5',{staticClass:"text-dark font-weight-bold my-2 mr-5"},[_vm._v(" "+_vm._s(_vm.breadcrumbs.length && _vm.breadcrumbs[0].title)+" ")]),(false)?_c('ul',{staticClass:"\n          breadcrumb breadcrumb-transparent breadcrumb-dot\n          font-weight-bold\n          p-0\n          my-2\n        "},[_c('li',{staticClass:"breadcrumb-item d-inline-flex"},[_c('router-link',{staticClass:"subheader-breadcrumbs-home",attrs:{"to":'/'}},[_c('i',{staticClass:"flaticon2-shelter text-muted icon-1x"})])],1),_vm._l((_vm.breadcrumbs),function(breadcrumb,i){return [_c('li',{key:(i + "-" + (breadcrumb.id)),staticClass:"breadcrumb-item d-inline-flex"},[(breadcrumb.route)?_c('router-link',{key:i,staticClass:"text-muted",attrs:{"to":breadcrumb.route}},[_vm._v(" "+_vm._s(breadcrumb.title)+" ")]):_vm._e(),(!breadcrumb.route)?_c('span',{key:i,staticClass:"text-muted"},[_vm._v(" "+_vm._s(breadcrumb.title)+" ")]):_vm._e()],1)]})],2):_vm._e()]),_c('div',{staticClass:"d-flex align-items-center"},[(_vm.title == 'Permissoes')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/createRule"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.c_Role),expression:"lista_permissoes_filial.c_Role"}],staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("GERENCIAR.CRIAR"))+" ")])]}}],null,false,1380980596)}),_c('router-link',{attrs:{"to":"/permissoes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.ra_Role),expression:"lista_permissoes_filial.ra_Role"}],staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,2015508643)})],1)],1):_vm._e()]),(_vm.title == 'Funcionarios')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/gerenFuncionarios"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createFuncionario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.c_Usuario),expression:"lista_permissoes_filial.c_Usuario"}],staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("CREACION.NOME"))+" ")])]}}],null,false,2017918039)})],1)],1):_vm._e(),(_vm.title == 'Paciente')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/gerenPaciente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createPaciente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.c_Usuario),expression:"lista_permissoes_filial.c_Usuario"}],staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("CREACION.NOME"))+" ")])]}}],null,false,2017918039)})],1)],1):_vm._e(),(_vm.title == 'Empresa')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/empresa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createEmpresa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.c_Empresa),expression:"lista_permissoes_filial.c_Empresa"}],staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("CREACION.NOME"))+" ")])]}}],null,false,3988571703)})],1)],1):_vm._e(),(_vm.title == 'Filial')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/filial"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createFilial"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.c_Filial),expression:"lista_permissoes_filial.c_Filial"}],staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("CREACION.NOME"))+" ")])]}}],null,false,207047351)})],1)],1):_vm._e(),(_vm.title == 'Año')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/ano"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createAno"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.c_Ano),expression:"lista_permissoes_filial.c_Ano"}],staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("CREACION.NOME"))+" ")])]}}],null,false,2272305111)})],1)],1):_vm._e(),(_vm.title == 'Salas')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/sala"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createSala"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("SALA.NOVA_SALA"))+" ")])]}}],null,false,2395542608)})],1)],1):_vm._e(),(_vm.title == 'Vinculos Salas')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/vinculo_sala"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/create_vinculo_sala"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("VINCULO.NOVO_VINCULO"))+" ")])]}}],null,false,2194033534)}),_c('router-link',{attrs:{"to":"/agendamentos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Agendamento ")])]}}],null,false,1550887614)})],1)],1):_vm._e(),(_vm.title == 'Productos')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/produto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createProduto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("PRODUTOS.NOVO_PRODUTO"))+" ")])]}}],null,false,4281661261)})],1)],1):_vm._e(),(_vm.title == 'Laboratorios')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/laboratorio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createLaboratorio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("LABORATORIO.NOVO_LAB"))+" ")])]}}],null,false,1706508333)})],1)],1):_vm._e(),(_vm.title == 'Substancias')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/substancia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createSubstancia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("SUBSTANCIA.NOVA_SUBSTANCIA"))+" ")])]}}],null,false,384080208)})],1)],1):_vm._e(),(_vm.title == 'Tipos')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/TipoProduto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createTipoProduto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("TIPOPRODUTO.NOME"))+" ")])]}}],null,false,1619073109)})],1)],1):_vm._e(),(_vm.title == 'Local')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/local"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createLocal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("LOCAL.NOVO_LOCAL"))+" ")])]}}],null,false,3370292766)})],1)],1):_vm._e(),(_vm.title == 'Setor')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/setor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createSetor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("SETOR.NOVO_SETOR"))+" ")])]}}],null,false,425573342)})],1)],1):_vm._e(),(_vm.title == 'Perfil')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/perfil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createPerfil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Novo Perfil ")])]}}],null,false,1265181463)})],1)],1):_vm._e(),(_vm.title == 'Arquivos')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/Arquivos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createArquivos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Novo ")])]}}],null,false,4127927891)})],1)],1):_vm._e(),(_vm.title == 'Atendimiento')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/atendimento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createAtendimento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("GERENCIAR.CRIAR"))+" ")])]}}],null,false,880579024)})],1)],1):_vm._e(),(_vm.title == 'Historico del Paciente')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/atendimento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createAtendimento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Nuevo atendimiento ")])]}}],null,false,1078367691)})],1)],1):_vm._e(),(_vm.title == 'Tipos Classe')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/tipoClasse"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createTipoClasse"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("CREACION.NOME"))+" ")])]}}],null,false,2410699982)})],1)],1):_vm._e(),(_vm.title == 'Salida de Producto')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/saidaProduto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createSaidaproduto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("CREACION.NOME"))+" ")])]}}],null,false,2410699982)})],1)],1):_vm._e(),(_vm.title == 'Especialidad')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/listaEspecialidade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createEspecialidade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Nueva ")])]}}],null,false,111347938)})],1)],1):_vm._e(),(_vm.title == 'Diagnóstico')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/listarDiagnostico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createDiagnostico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("CREACION.NOME"))+" ")])]}}],null,false,2410699982)})],1)],1):_vm._e(),(_vm.title == 'Agendamentos')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/vinculo_sala"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Accesar Vinculos Sala ")])]}}],null,false,2107094906)})],1)],1):_vm._e(),(_vm.title == 'Relatorios')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/createrelatorio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("PRODUTOS.PRODUTO"))+" ")])]}}],null,false,899753367)}),_c('router-link',{attrs:{"to":"/relatorioEstoque"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("ESTOQUES.ATIVOS"))+" ")])]}}],null,false,3509589421)})],1)],1):_vm._e(),(_vm.title == 'Relatorio Geral')?_c('div'):_vm._e(),(_vm.title == 'Fila Atendimientos')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/relatorioFilial"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-outline-secondary font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Atendimientos Recientes ")])]}}],null,false,1963689343)}),_c('router-link',{attrs:{"to":"/relatorioFilial"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-outline-secondary font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Historico de atendimentos ")])]}}],null,false,716186749)})],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }